<template>
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
    <b-row v-if="!practiceId">
        <b-col>
            <b-form-group
            id="user-invite-practices"
            label="Practice">
                <multiselect
                    openDirection="bottom"
                    placeholder="Search by practice name" 
                    :custom-label="practiceLabel"
                    v-model="selectedPractice"
                    track-by="practice_id"
                    :options="selectablePractices"
                    :loading="practicesLoading"
                    :internal-search="false"
                    :show-labels="false"
                    @search-change="debouncePractices"
                    :showNoOptions="true">
                    <template slot="noResult">
                        No practices matched your search
                    </template>
                    <template slot="noOptions">
                        Start typing a practice name...
                    </template>
                </multiselect>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="given_name"
            label="First name">
            <b-input-group>
                <b-form-input v-model="form.given_name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="middle_name_or_initial"
            label="Middle name">
            <b-input-group>
                <b-form-input v-model="form.middle_name_or_initial"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="family_name"
            label="Last name">
            <b-input-group>
                <b-form-input v-model="form.family_name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="suffix"
            label="Suffix">
            <b-input-group>
                <b-form-input v-model="form.suffix"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="ssn"
            label="SSN">
            <b-input-group>
                <b-form-input v-model="form.ssn"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="ptan"
            label="PTAN">
            <b-input-group>
                <b-form-input v-model="form.ptan"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="npi"
            label="NPI">
            <b-input-group>
                <b-form-input v-model="form.npi"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
        <b-form-group
            id="provider_type"
            label="Billing type">
            <b-form-select v-model="form.provider_type" :options="types">
                <template #first>
                <b-form-select-option :value="null" disabled>Select type</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="email"
            label="Email">
            <b-input-group>
                <b-form-input 
                    v-model="form.email"
                    type="email"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
                id="date-of-birth"
                label="Date of birth"
                style="text-align:left">
            <b-form-input type="date" 
            v-model="form.date_of_birth"
            class="mb-4"
            today-button
            reset-button
            close-button></b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
                id="start-date"
                label="Start date"
                style="text-align:left">
            <b-form-input type="date" 
            v-model="form.start_date" 
            class="mb-4"
            today-button
            reset-button
            close-button></b-form-input>
            </b-form-group>
        </b-col>
    </b-row>
    </div>
    <b-row>
        <b-col style="text-align:right">
            <b-button @click="onSubmit" class="avo-primary-btn">
                    Create provider
            </b-button>
        </b-col>
    </b-row>
</b-form>
</template>

<script>
import { make_url, authenticated_request, debounce, alertsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ProviderAdd',
  components:{
    Multiselect
  },
  mixins: [alertsMixin],
  props: ['practiceId'],
  data: function(){
      return {
            form: {
                given_name: null,
                middle_name_or_initial: null,
                family_name: null,
                suffix: null,
                date_of_birth: null,
                ssn: null,
                ptan: null,
                npi: null,
                email: null,
                provider_type: null,
                start_date: null,
            },
            provider: {},
            types: [],

            selectablePractices: [],
            selectedPractice: null,
            practiceSearchText: "",
            practicesLoading: false,
      }
  },
  methods: {
    clearData(){
        this.form = {
            given_name: null,
            middle_name_or_initial: null,
            family_name: null,
            suffix: null,
            date_of_birth: null,
            ssn: null,
            ptan: null,
            npi: null,
            email: null,
            provider_type: null,
            start_date: null,
        }
        this.provider = {},
        this.selectedPractice = null,
        this.hideError();
    },
    validateInputs(){
        this.hideError();
        
        //validate inputs
        if ((this.form.given_name) == null || (this.form.given_name == "")){
            this.showError("Missing field: first name");
            return false;
        }
        if ((this.form.family_name) == null || (this.form.family_name == "")){
            this.showError("Missing field: last name");
            return false;
        }
        
        this.provider['given_name'] = this.form.given_name;
        this.provider['family_name'] = this.form.family_name;
        
        var fields = ['middle_name_or_initial', 'suffix', 'date_of_birth', 'ssn', 'ptan', 'npi', 'email', 'provider_type', 'start_date']
        
        for (var i = 0; i < fields.length; i++){
            if ((this.form[fields[i]] != null) && (this.form[fields[i]] != "")){
                //if ((fields[i] != 'date_of_birth') && (fields[i] != 'start_date')){
                this.provider[fields[i]] = this.form[fields[i]];
                //}
                //else{
                //    this.provider[fields[i]] = this.form[fields[i]] + "T00:00:00+00:00";
                //}
            }
        }
        
        return true
    },
    addProvider(){
        const url = make_url("/providers");
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: this.provider
        }).then(function(response){
            if (response){
                const newProvider = {
                    provider_id: response.data,
                    full_name_with_suffix: self.form.given_name + ' ' + self.form.family_name + ' '
                }
                self.$emit('submittedProvider', newProvider);
                if(self.practice_id){
                    self.associateProviderWithPractice(response.data, self.practice_id);
                }
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    associateProviderWithPractice(provider_id, practice_id){
        const url = make_url("/practices/" + practice_id + "/providers");
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: {
                provider_id: provider_id
            }
        }).then(function(response){
            if (response){
                self.$emit('associatedProviderWithPractice', provider_id, practice_id);
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    onSubmit(){
        if (this.validateInputs()){
            this.addProvider();
        }
    },
    practiceSearchChange: function(searchQuery) {
        this.practicesLoading = true;
        this.practiceSearchText = searchQuery
        if (searchQuery.length > 0 ) {
            this.practiceAutocomplete();
        } else {
            this.practicesLoading = false;
        }
    },
    practiceAutocomplete() {
        var vm = this;
        const url = make_url("/reports/practices/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.practiceSearchText,
                limit: 10
            }
        }).then(function(response){
            vm.practicesLoading = false;
            const practices = response.data.practices;
            vm.selectablePractices = practices || [];
            return practices || [];
        }).catch(function(errors){
            console.log(errors.response)
            vm.showError(errors);
        });
    },
    practiceLabel(practice){
        if (practice.name){
            return practice.name
        } else {
            return practice.practice_id
        }
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
    computed: {
        practice_id: function() {
            if (this.selectedPractice) {
                return this.selectedPractice.practice_id;
            }
            else if (this.practiceId) {
                return this.practiceId;
            } else {
                return null;
            }
        }

    },
    created(){
        this.debouncePractices = debounce(this.practiceSearchChange, 300)
        var self = this;
        authenticated_request({
            method: "get",
            url: make_url("/forms/providers/filters")
        }).then(function(response){
            self.types = response.data.types;
        }).catch(function(errors){
            self.showError(errors);
        });
    }
}
</script>

<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5 !important;
}
</style>