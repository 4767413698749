<template>
<div id="invite-user">
<avo-alert :avoAlerts.sync="avoAlerts"></avo-alert>
<b-row>
<b-col>
<b-form ref="addUserForm" @submit="onSubmit" @reset="onReset">
    <b-card class="mt-2 mb-2">
    <b-form-group
    label-cols-lg="3"
    label="User details"
    label-size="lg">
    <b-form-row>
        <b-col>
            <b-form-group
            id="user-invite-given-name-label"
            label="First name"
            label-for="user-invite-given-name">
            <b-form-input
                id="user-invite-given-name"
                v-model="form.givenName"
                type="text"
                placeholder="Given name of new user"
                required
            ></b-form-input>
            </b-form-group>
        </b-col>

        <b-col>
            <b-form-group
            id="user-invite-family-name-label"
            label="Last name"
            label-for="user-invite-family-name">
            <b-form-input
                id="user-invite-family-name"
                v-model="form.familyName"
                type="text"
                placeholder="Family name of new user"
                required
            ></b-form-input>
            </b-form-group>
        </b-col>
    </b-form-row>
        <b-form-group
        id="user-invite-email-label"
        label="Email address">
        <b-form-input
            id="user-invite-email"
            v-model="form.email"
            type="email"
            placeholder="Type the email address of the new user"
            required
        ></b-form-input>
        </b-form-group>
    </b-form-group>
    </b-card>

    <b-card class="mb-2">
        <b-form-group
        label-cols-lg="3"
        label="Permissions"
        label-size="lg">
            <b-form-group
            id="user-invite-role"
            label="Role">
                <multiselect
                    v-model="form.roles" 
                    :options="rolesOptions" 
                    :show-labels="false"
                    :hide-selected="true"
                    :close-on-select="false" 
                    track-by="text"
                    label="text"
                    :multiple="true"
                    placeholder="-- Select Role(s) --"
                    required>
                </multiselect>
            </b-form-group>
        </b-form-group>
    </b-card>
    <b-card v-if="doCreateProvider" class="mb-2">
        <div class="d-flex ">
            <b-form-group
            label-cols-lg="3"
            label-size="lg">
            <template #label>
                Provider
                <b-form-text class="mt-2 mr-3">
                    Please select the provider to be associated with this user account
                </b-form-text>
            </template>
                <b-form-group
                label="Select a Provider">
                    <provider-multiselect 
                        ref="providerMultiselectInstance" 
                        v-model="selectedProvider" 
                        :multiple="false" 
                        :close-on-select="true"/>
                </b-form-group>
            </b-form-group>
            <b-button
                class="avo-primary-btn"
                style="margin-top:32px;margin-left:12px;"
                v-b-modal.new-provider>
                New Provider
            </b-button>
            <b-modal id="new-provider" title="Create new provider" hide-footer size="lg">
                <provider-add 
                    @submittedProvider="newProviderModalSubmittedCallback($event)"
                />
            </b-modal>
        </div>  
    </b-card>

    <b-card v-if="doAssociatePractice" class="mb-2">
        <div class="d-flex ">
            <b-form-group
            label-cols-lg="3"
            label-size="lg">
            <template #label>
                Practice
                <b-form-text class="mt-2 mr-3">
                    Please select the practice to be associated with this administrative account
                </b-form-text>
            </template>
                <b-form-group
                label="Select a Practice">
                    <practice-multiselect v-model="selectedPractice"/>
                </b-form-group>
            </b-form-group>
        </div>  
    </b-card>
    
    <b-card v-if="doCreateWorker" class="mb-2">
        <b-form-group
        label-cols-lg="3"
        label-size="lg">
        <template #label>
            Assignments
            <b-form-text class="mt-2 mr-3">
                Please select the practices, payers, and CPT codes in which this user will specialize
            </b-form-text>
        </template>
            <b-form-group
            id="user-invite-practices"
            label="Practices">
                <practice-multiselect v-model="form.practices" :multiple="true"/>
            </b-form-group>

            <b-form-group
            id="user-invite-payers"
            label="Payers">
            <b-col>
                <payer-multiselect v-model="form.payers" :multiple="true"/>
            </b-col>
                <b-col v-if="payersGroupsOptions && (payersGroupsOptions.length > 0)">
                    <b-form-group
                        label="Add by Payer Group:">
                        <multiselect
                            :show-labels="false"
                            :value="selectedPayerGroups"
                            :options="payersGroupsOptions"
                            :close-on-select="false"
                            :taggable="true"
                            :multiple="true"
                            track-by="value"
                            label="text"
                            :disabled="optionsLoading"
                            placeholder="-- Select Payer Group --"
                            @select="addPayersByGroup"
                            @remove="popPayerGroup">
                        </multiselect>
                    </b-form-group>
                    </b-col>
            </b-form-group>

            <b-form-group
            id="user-invite-codes"
            label="CPT Codes">
            <code-multiselect v-model="form.codes" :multiple="true"/>
            </b-form-group>
        </b-form-group>
    </b-card>

    <div class="d-flex justify-content-end pb-5 pt-2">
        <b-button class="avo-basic-btn ml-2"  type="reset" variant="danger">Clear Fields</b-button>
        <b-button class="avo-primary-btn ml-2"  type="submit" variant="primary">Add New User</b-button>
    </div>
</b-form>
</b-col>
</b-row>
</div>
</template>

<script>
import rolesEnum from '../../rolesEnum.js'
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'
import ProviderAdd from '../providers/ProviderAdd.vue'
import PracticeMultiselect from '../multiselects/PracticeMultiselect.vue'
import PayerMultiselect from '../multiselects/PayerMultiselect.vue'
import ProviderMultiselect from '../multiselects/ProviderMultiselect.vue'
import CodeMultiselect from '../multiselects/CodeMultiselect.vue'
import AvoAlert from '../AvoAlert.vue'

export default {
  name: 'InviteUser',
  mixins: [alertsMixin],
  components: { 
      Multiselect,
      ProviderAdd,
      PracticeMultiselect,
      PayerMultiselect,
      ProviderMultiselect,
      CodeMultiselect,
      AvoAlert,
  },
  data: function(){
      return {
          form: {
              email: '',
              roles: [],
              practices: [],
              payers: [],
              codes: [],
          },

          payersGroupsOptions: [],


          selectedPayerGroups: [],
        // These roles are grabbed from a beforeMount get request
          rolesOptions: [],
        // Worker assignment data
          selectedProvider: null,
          selectedPractice: null,
        // Feedback loop variables
          providerNotProvided: false,
          practiceNotProvided: false,

          optionsLoading: true,

        showInfo: false,
        showSuccess: false,
      }
  },
  methods: {
    onSubmit(event) {
        event.preventDefault()

        if(this.doCreateProvider && !this.selectedProvider){
            this.providerNotProvided = true;
            this.showAvoAlert("Please select a provider");
            return false
        }

        if(this.doAssociatePractice && !this.selectedPractice){
            this.practiceNotProvided = true;
            this.showAvoAlert("Please select a practice");
            return false
        }

        // Invite the user
        const invite_url = make_url("/users/invite");
        const invite_data = {
            username: this.form.email, 
            email: this.form.email,
            given_name: this.form.givenName,
            family_name: this.form.familyName
        }

        // Endpoint data for setting role permissions for the user
        const roles_url = make_url("/roles/users");
        const roles = this.form.roles.map((item) => item.value);

        var rolesData = {
            roles: roles,
        }

        // Endpoint data for setting up the new worker
        const worker_data = {
            given_name: this.form.givenName,
            family_name: this.form.familyName,
        }
        const worker_url = make_url("/workers");

        // Create the user via invite-user endpoint
        const vm = this;
        const promise = authenticated_request({
            method: "post",
            url: invite_url,
            data: invite_data
        })
        .then(function(response){
            vm.showInfoMessage("Inviting new user in progress ...");
            var entityPromises = [];

            // Add role permissions to the new user
            const role_promise = authenticated_request({
                method: "post",
                url: roles_url,
                data: {user_id: response.data.user_id, ...rolesData}
            });
            entityPromises.push(role_promise);

            // Update provider
            if(vm.selectedProvider){
                const provider_url = make_url("/providers/" + vm.selectedProvider.provider_id);
                const provider_promise = authenticated_request({
                    method: "put",
                    url: provider_url,
                    data: { user_id: response.data.user_id }
                })
                entityPromises.push(provider_promise);
            }

            // Update practice association
            if(vm.selectedPractice){
                const practice_user_url = make_url("/practices/" + vm.selectedPractice.practice_id + "/users");
                const practice_user_promise = authenticated_request({
                    method: "put",
                    url: practice_user_url,
                    data: { user_id: response.data.user_id }
                })
                entityPromises.push(practice_user_promise);
            }

            // Create worker
            const worker_promise = authenticated_request({
                method: "post",
                url: worker_url,
                data: { default: true, 
                        user_id: response.data.user_id, 
                        ...worker_data}
            })
            .then(function(response){
                // Add worker associations
                const worker_id = response.data;

                var association_promises = [];

                if (vm.form.payers.length > 0) {
                    // Make payers associations
                    const payers_url = make_url("/workers/" + worker_id.toString() + "/payers");
                    const payers_list = vm.form.payers.map(tuple => tuple.payer_id);
                    const payer_promise = authenticated_request({
                        method: "post",
                        url: payers_url,
                        data: {payers: payers_list}
                    });
                    association_promises.push(payer_promise);
                }
                if (vm.form.practices.length > 0) {
                    // Make practices associations
                    const practices_url = make_url("/workers/" + worker_id.toString() + "/practices");
                    const practices_list = vm.form.practices.map(tuple => tuple.practice_id);
                    const practices_promise = authenticated_request({
                        method: "post",
                        url: practices_url,
                        data: {practices: practices_list}
                    });
                    association_promises.push(practices_promise);
                }
                if (vm.form.codes.length > 0) {
                    // Make codes associations
                    const codes_url = make_url("/workers/" + worker_id.toString() + "/codes");
                    const codes_list = vm.form.codes.map(tuple => Object({code: tuple.code}));
                    const codes_promise = authenticated_request({
                        method: "post",
                        url: codes_url,
                        data: {codes: codes_list}
                    });
                    association_promises.push(codes_promise);
                }

                return Promise.all(association_promises)
            });
            entityPromises.push(worker_promise);
            
            return Promise.all(entityPromises)
        })
        .then(function(values){
            vm.$emit('userInvited');
            vm.showAlert = false;
            console.log(values);
            // If no assignments are set, then association_promises will not have a response status
            if (values.every((response) => !response.status || response.status == 201 || response.status == 200 || response.status == 204)) {
                vm.showAvoSuccess("User invite email sent");
                vm.resetForm();
            }
        })
        .catch(function(error){
            console.log("Error");
            console.log(error.response);
            const message = (error.response && error.response.data) ? error.response.data.msg : error;
            vm.showAvoAlert(message);
        });
        return promise;

    },
    addNewProviderToDropdown(newProvider){
        this.$refs.providerMultiselectInstance.addNewProviderToDropdown(newProvider);
    },
    hideNewProviderModal(){
        console.log("hideNewProvider callback")
        this.$bvModal.hide('new-provider');
    },
    newProviderModalSubmittedCallback(newProvider){
        this.addNewProviderToDropdown(newProvider);
        this.hideNewProviderModal();
    },
    resetForm() {
        this.$refs.addUserForm.reset();
    },
    onReset(){
        this.form = {
              email: '',
              roles: [],
              payers: [],
              practices: [],
              codes: [],
          };
        this.providerNotProvided = false;
        this.selectedProvider = null;
        this.practiceNotProvided = false;
        this.selectedPractice = null;
    },

    popPayerGroup(payer_group){
        this.selectedPayerGroups.pop(this.selectedPayerGroups.indexOf(payer_group));
    },
    addPayersByGroup(payer_group){
        var vm = this;
        this.selectedPayerGroups.push(payer_group);
        authenticated_request({
            method: "get",
            url: make_url("/payers/groups/payers").toString(),
            params:{
                payer_group_id: payer_group.value
            }
        }).then(function(response){
            const payersData = response.data.payers;
            var payerData;
            var found = false;
            for (var index = 0; index < payersData.length; index++){
                found = false;
                payerData = payersData[index];
                for (var index2 = 0; index2 < vm.form.payers.length; index2++){
                    if (vm.form.payers[index2].payer_id == payerData.payer_id){
                        found = true;
                        break;
                    }
                }
                if (!found){
                    vm.form.payers.push(payerData);
                }
            }
        });
    },
    
    //alert methods
    showSuccessMessage(alertMessage){
        this.showAvoSuccess(alertMessage);
    },
    showInfoMessage(alertMessage){
        this.showAvoInfo(alertMessage);
    },
  },
  watch: {
      selectedProvider(newValue){
          if(newValue){
              this.providerNotProvided = false;
          }
      },
      selectedPractice(newValue){
          if(newValue){
              this.practiceNotProvided = false;
          }
      }
  },
  computed: {
      selectedRoleNames(){
          var roleNames = [];
          if(this.form.roles){
              roleNames = this.form.roles.map((item) => item.text);
          }
          return roleNames
      },
      // returns true if a worker should be created and associated with the user
      doCreateWorker(){
          if(this.selectedRoleNames.length == 0){
              return false;
          }
          const assignableRoles = [rolesEnum.ISSUES_WORKER, rolesEnum.WORKER, rolesEnum.POSTER, rolesEnum.TRAINEE,]
          return this.selectedRoleNames.some(val => assignableRoles.includes(val))
      },
      doCreateProvider(){
          if(this.selectedRoleNames.length == 0){
              return false;
          }
          const providerRoles = [rolesEnum.PHYSICIAN, rolesEnum.ADMINISTRATIVE_PHYSICIAN,]
          return this.selectedRoleNames.some(val => providerRoles.includes(val))
      },
      doAssociatePractice(){
          if(this.selectedRoleNames.length == 0){
              return false;
          }
          const practiceAdminRoles = [rolesEnum.PRACTICE_ADMIN,]
          return this.selectedRoleNames.some(val => practiceAdminRoles.includes(val))
      },
  },
    mounted(){
        var vm = this;

        // Get available roles to assign
        authenticated_request({
            method: "get",
            url: make_url("/roles"),
        }).then(function(response){
            const roleData = response.data.roles;
            for (var index = 0; index < roleData.length; index++){
            vm.rolesOptions.push({value: roleData[index].role_id, text: roleData[index].name});
            }
        });

        // Get available payer groups to assign
        authenticated_request({
            method: "get",
            url: make_url("/payers/groups"),
        }).then(function(response){
            const payersGroupsData = response.data.payers_groups;
            for (var index = 0; index < payersGroupsData.length; index++){
            vm.payersGroupsOptions.push({value: payersGroupsData[index].payer_group_id, text: payersGroupsData[index].name});
            vm.optionsLoading = false;
            }
        });

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5 !important;
}
::v-deep .invalid .multiselect__tags {
    border:1px solid #DC3545;
    border-radius: 5px;
}
</style>
