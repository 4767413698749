
<template>
<div>
    <b-alert
        v-for="(alert, index) in internalAvoAlerts"
        :show="alert.show"
        :key="'alert'+index"
        :variant="alert.variant"
        :dismissible="alert.dismissible"
        @dismissed="dismissAlert(index)"
        class="avo-text-bold"
        >
        {{ alert.message }}
    </b-alert>
</div>
</template>

<script>

export default {
    name: 'AvoAlert',
    props: {
        avoAlerts: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            internalAvoAlerts: [],
        }
    },
    methods: {
        dismissAlert(index) {
            this.internalAvoAlerts.splice(index, 1);
            this.$emit("update:avoAlerts", this.internalAvoAlerts);
        },
    },
    watch: {
        avoAlerts: {
            handler(newValue) {
                this.internalAvoAlerts = newValue;
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>
