<template>
    <multiselect
        openDirection="bottom"
        placeholder="Search by provider name or address" 
        :custom-label="providerLabel"
        v-model="selectedProvider"
        track-by="provider_id"
        :options="selectableProviders"
        :loading="loading || providersLoading"
        :internal-search="false"
        :close-on-select="closeOnSelect"
        :multiple="multiple"
        :show-labels="false"
        @search-change="debounceProviders"
        :showNoOptions="true">
        <template slot="noResult">
            No providers matched your search
        </template>
        <template slot="noOptions">
            Start typing a provider name or address...
        </template>
    </multiselect>
</template>

<script>
import { make_url, authenticated_request, debounce } from '../../common.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ProviderMultiselect',
  components: { 
      Multiselect,
  },
  props: {
      value: [Object, Array],
      multiple: {
          type: Boolean,
          default: false
      },
      loading: {
          type: Boolean,
          default: false,
      },
      closeOnSelect: {
          type: Boolean,
          default: false,
      },
  },
  data: function(){
      return {
          selectableProviders: [],
          selectedProvider: null,
          providerSearchText: "",
          providersLoading: false,
      }
  },
  methods: {
    providerSearchChange: function(searchQuery) {
        this.providersLoading = true;
        this.providerSearchText = searchQuery
        if (searchQuery.length > 0 ) {
            this.providerAutocomplete();
        } else {
            this.providersLoading = false;
        }
    },
    providerAutocomplete() {
        var vm = this;
        const url = make_url("/reports/providers/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.providerSearchText,
                limit: 10
            }
        }).then(function(response){
            vm.providersLoading = false;

            const providers = response.data.providers;
            vm.selectableProviders = providers || [];
            return providers || [];
        }).catch(function(errors){
            vm.showError(errors);
        });
    },
    providerLabel(provider){
        return provider.full_name_with_suffix
    },
    addNewProviderToDropdown(newProvider){
        this.selectableProviders.push(newProvider);
        this.selectedProvider = newProvider;
    },
  },
  watch: {
      selectedProvider() {
          this.$emit('input', this.selectedProvider)
      },
      value: {
          deep: true,
          immediate: true,
          handler: function(newValue) {
            if (newValue) {
                this.selectedProvider = newValue;
            }
          }
      },
  },
    created(){
        this.debounceProviders = debounce(this.providerSearchChange, 300)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5 !important;
}
::v-deep .invalid .multiselect__tags {
    border:1px solid #DC3545;
    border-radius: 5px;
}
</style>